<template>
  <div class="xiaoShanInvestment" id="xiaoShanInvestment">
      <div class="top">
        <router-link :to="{path:'/xiaoShanInvestmentM',query:{jumpType:'tab',range_id: range_id}}">
          招商产业布局策略分析
        </router-link>
      </div>
      <div class="contert">
          <div class="contertL">
              <div class="conterL1">
                  <div class="heatLi" v-for="(item,index) in industryHot" :key="index">
                      <div class="heatNum">
                        <div class="heatNumLi">{{item.hot}}</div>
                        <img class="arrow" v-if="item.status == 2" src="@/assets/images/xiaoShanArrow01.png" alt="">
                        <img class="arrow" v-if="item.status == 1" src="@/assets/images/xiaoShanArrow02.png" alt="">
                      </div>
                      <div class="heatName">{{item.industry_name}}</div>
                  </div>
              </div>
              <div class="conterL2">
                  <div class="title02">
                      产业格局
                  </div>
                  <div class="box02">
                        <div class="dashedLi"></div>
                        <div class="dashedLi"></div>
                        <div class="dashedLi"></div>
                        <div class="dashedLi"></div>
                        <div class="data02Li" :alter='item.industry_name' v-for="(item,index) in industryPattern" :key="index" :style="{backgroundColor:color[index],borderRadius:'50%',width:tzlb[index]+0.3+'rem',height:tzlb[index]+0.3+'rem',lineHeight:tzlb[index]+0.3+'rem',textAlign:'center',fontSize:'0.1rem',color:'#fff',right:tzzqb[index]*8+'rem',bottom:tzlb[index]*2+0.2+'rem'}">
                            {{item.industry_name}}
                        </div>
                  </div>
                  <div class="conterL2x"></div>
                  <div class="conterL2y"></div>
                  <div class="conterL2xx">投资周期(短)</div>
                  <div class="conterL2yy">投资量(大)</div>
              </div>
              <div class="conterL3">
                <div class="title02">
                  产业分布
                </div>
                <div class="selectType">
                  <div class="industrialType" @click="changeshowRankIndustrialTypeList">{{rankIndustrialTypeName}}<i class="el-icon-arrow-down"></i></div>
                  <div class="industrialTypeList" v-if="showRankIndustrialTypeList">
                    <div class="industrialTypeLi" v-for="(item,index) in industryXs" :key="index" @click="getRankIndustrialType(item.id,item.name)">{{item.name}}</div>
                  </div>
                </div>
                <div class="conterL3Img">
                  <img class="mapImg" src="@/assets/images/xiaoShanMap.gif" alt="">
                </div>
                <div class="ranking">
                  <div class="rankingTitle">城市排名</div>
                  <div class="cityLi" v-for="(item,index) in industryCityRank" :key="index">
                    <div class="cityName">{{item.rank}}、{{item.area_title}}</div>
                    <div class="cityCount" :style="{width:item.percentage*1.5 + 'rem'}"></div>
                  </div>
                </div>
                <!-- <div id="map" :style="{width: '3.8rem', height: '3.1rem'}"></div> -->
              </div>
          </div>
          <div class="contertR">
              <div class="conterR1">
                  <div id="zhexian" :style="{width: '9rem', height: '3.6rem'}"></div>
              </div>
              <div class="conterR2">
                  <div class="title02">
                      细分产业
                  </div>
                  <div class="selectType">
                    <div class="industrialType" @click="changeshowindustrialTypeList">{{industrialTypeName}}<i class="el-icon-arrow-down"></i></div>
                    <div class="industrialTypeList" v-if="showindustrialTypeList">
                      <div class="industrialTypeLi" v-for="(item,index) in industryXs" :key="index" @click="getIndustrialType(item.id,item.name)">{{item.name}}</div>
                    </div>
                  </div>
                  <div class="tips">
                      <div class="describe">搜索指数</div>
                      <div class="circles">
                          <div class="circle"></div>
                          <div class="circle"></div>
                          <div class="circle"></div>
                      </div>
                  </div>
                  <div class="graphBubbles">
                    <div class="graphBubble" v-for="(item,index) in industryDetail" :key="index" :style="{top:Math.random()*0.2 + 'rem',left:Math.random()*0.2 + 'rem'}">
                      <div class="bubble" :style="{width:item.size*0.3 + 0.1 + 'rem',height:item.size*0.3 + 0.1 + 'rem',backgroundColor:color02[index]}"></div>
                      <div class="bubbleName">{{item.industry_name}}</div>
                    </div>
                    <div class="bigGraphBubble" :title="industrialTypeName">
                      {{industrialTypeName}}
                    </div>
                  </div>
              </div>
              <div class="conterR3">
                <div class="title02">
                    产业招商项目
                </div>
                <div class="selectType">
                  <div class="industrialType" @click="changeshowProjectIndustrialTypeList">{{projectIndustrialTypeName}}<i class="el-icon-arrow-down"></i></div>
                  <div class="industrialTypeList" v-if="showProjectindustrialTypeList">
                    <div class="industrialTypeLi" v-for="(item,index) in industryXs" :key="index" @click="getProjectIndustrialType(item.id,item.name)">{{item.name}}</div>
                  </div>
                </div>
                <div class="selectType">
                  <div class="industrialType" @click="changeshowProjectTypeList">{{projectTypeName}}<i class="el-icon-arrow-down"></i></div>
                  <div class="industrialTypeList" v-if="showProjectTypeList">
                    <div class="industrialTypeLi" v-for="(item,index) in projectTypeList" :key="index" @click="getProjectIType(item.type,item.name)">{{item.name}}</div>
                  </div>
                </div>
                <div class="projectListHead">
                  <div>名称</div>
                  <div>介绍</div>
                  <div>领域</div>
                  <div>阶段/地区</div>
                  <div>特色标签</div>
                  <div>得分</div>
                  <div>潜在投资</div>
                  <div>联系方式</div>
                  <div>联系人</div>
                </div>
                <div class="projectList">
                  <div class="projectLi" v-for="(item,index) in projectList" :key="index">
                    <div class="logo">
                      <img v-if="item.logo !=''" :src="item.logo" :key="item.logo" alt="">
                      <img v-else src="@/assets/images/newlogo.png" alt="">
                    </div>
                    <div class="companyName">{{item.company_name}}</div>
                    <div class="intro">{{item.intro}}</div>
                    <div class="industry">{{item.industry}}</div>
                    <div class="stage">{{item.stage}}<span v-if="item.stage && item.city">·</span>{{item.city}}</div>
                    <div class="special">
                      <span v-if="item.special.length >= 1">{{item.special[0].special_name}}</span>
                    </div>
                    <div class="score">{{item.score}}</div>
                    <div class="investor">{{item.investor}}</div>
                    <div class="mobile">{{item.mobile}}</div>
                    <div class="corporation">{{item.corporation}}</div>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</template> 

<script>
import { error, success, warning } from "@/utils/notification";
import Vue from "vue";
// import "@/utils/rem_1920";
import echarts from "echarts";
Vue.prototype.$echarts = echarts;
// // 散点图
// require('echarts/lib/chart/scatter')
// // 散点图放大
// require('echarts/lib/chart/effectScatter')
// // 地图
// require('echarts/lib/chart/map')
// // 图例
// require('echarts/lib/component/legend')
// // 提示框
// require('echarts/lib/component/tooltip')
// // 地图geo
// require('echarts/lib/component/geo')
// import 'echarts/map/js/china.js';
export default {
  name: "",
  data() {
    return {
      organ_id: '',
      data_type: 'zst',
      color: [
        "#265FD0",
        "#5AE771",
        "#645AE7",
        "#FF8F2B",
        "#E75AE5",
        "#C8FA66",
        "#265FD0",
        "#5AE771",
        "#645AE7",
        "#FF8F2B",
        "#E75AE5",
        "#C8FA66",
        "#265FD0",
        "#5AE771",
        "#645AE7",
        "#FF8F2B",
        "#E75AE5",
        "#C8FA66"
      ],
      color02: [
        "#FAAB66",
        "#E85966",
        "#2ADCE1",
        "#966AE9",
        "#265FD0",
        "#E75AE5",
        "#5AE771",
        "#645AE7",
        "#265FD0",
        "#5AE771",
        "#645AE7",
        "#FF8F2B",
        "#E75AE5",
        "#C8FA66",
        "#265FD0",
        "#645AE7",
        "#FF8F2B",
        "#E75AE5",
        "#C8FA66",
        "#265FD0",
        "#5AE771",
        "#645AE7",
        "#FF8F2B",
        "#E75AE5",
        "#C8FA66",
        "#265FD0",
        "#265FD0",
        "#E75AE5",
        "#5AE771",
        "#645AE7",
        "#265FD0",
        "#5AE771",
        "#645AE7",
        "#FF8F2B",
        "#E75AE5",
        "#C8FA66",
        "#265FD0",
        "#5AE771",
        "#645AE7",
        "#FF8F2B",
        "#E75AE5",
        "#C8FA66",
        "#265FD0",
        "#5AE771",
        "#645AE7",
        "#FF8F2B",
        "#E75AE5",
        "#C8FA66"
      ],
      tzl: [],
      tzlb: [],
      tzzq: [],
      tzzqb: [],
      industryXs: [],
      industryHot: [],
      industryPattern: [],
      industryCityRank: [],
      industryTrend: [],
      industryDetail: [],
      industry: [1, 2, 3, 4, 5, 6, 7, 8], // 产业趋势筛选项
      industryName: [],
      industryCounts: [],
      projectList:[],
      industrialTypeId: 1, // 产业类型(细分产业)
      rankIndustrialTypeId: 1, // 产业类型(产业分布)
      projectIndustrialTypeId: 1, // 产业类型(招商项目)
      industrialTypeName: "高端装备制造", // 产业类型(细分产业)
      rankIndustrialTypeName: "高端装备制造", // 产业类型(产业分布)
      projectIndustrialTypeName: "高端装备制造", // 产业类型(招商项目)
      projectTypeName: "世界500强", // 产业类型(招商项目)
      showindustrialTypeList: false,
      showRankIndustrialTypeList: false,
      showProjectindustrialTypeList: false,
      showProjectTypeList:false,
      projectListType: 1,
      projectTypeList: [
        {
          name:'世界500强',
          type: 1
        },
        {
          name:'中国500强',
          type: 2
        },
        {
          name:'独角兽',
          type: 3
        },
        {
          name:'全网项目',
          type: 4
        },
      ],
      jumpType:'',
      range_id: '',
      addend: 0,
      multiplier: 1,
      mountedTime: null
    };
  },
  created() {
    if(this.$route.query.jumpType){
      this.jumpType = this.$route.query.jumpType;
    }
    let organ_id = sessionStorage.organizationId;
    if(organ_id == undefined){
      organ_id = this.$route.query.organ_id;
    }
    this.organ_id = organ_id;
    this.range_id = this.$route.query.range_id
  },
  methods: {
    zhexian() {
      // 折线图教程https://www.cnblogs.com/dancer0321/p/8253676.html
      let myChart = this.$echarts.init(document.getElementById("zhexian"));
      myChart.setOption({
        color: [
          "#FAAB66",
          "#E85966",
          "#2ADCE1",
          "#966AE9",
          "#265FD0",
          "#E75AE5",
          "#5AE771",
          "#645AE7"
        ],
        title: {
          text: "产业趋势",
          textStyle: {
            color: "#C5D8FF"
            // fontSize: "0.24rem",
            // fontWeight: 600,
          }
        },
        tooltip: {
          trigger: "axis",
          // formatter: function(data){
          //   return
          // },
        },
        legend: {
          align: "right",
          top: "10%",
          textStyle: {
            color: "#C5D8FF"
          },
          // itemWidth: '0.1rem',
          // itemHeight: '0.1rem',
          icon: "square",
          borderColor: "red",
          data: this.industryName,
          itemGap: 20
        },
        grid: {
          top: "40%",
          left: "2%",
          right: "2%",
          bottom: "10%",
          containLabel: true
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月"
          ],
          //坐标轴颜色
          axisLine: {
            lineStyle: {
              color: "#265FD0",
              opacity: 0.5
            }
          },
          axisLabel: {
            color: "#92A7E0"
          },
          boundaryGap: ["100%", "100%"]
          //x轴文字旋转
          // axisLabel:{
          //     rotate:30,
          //     interval:0
          // },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#265FD0",
              opacity: 0.5
            }
          },
          axisLabel: {
            color: "#92A7E0"
          },
          // 水平方向网格样式
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#265FD0"],
              width: 1,
              type: "dashed",
              opacity: 0.5
            }
          }
        },
        series: [
          {
            name: this.industryName[0],
            type: "line",
            // stack: '总量',
            itemStyle: {
              normal: {
                lineStyle: {
                  // color: "#FAAB66",
                  width: 1
                }
              }
            },
            data: this.industryCounts[0]
          },
          {
            name: this.industryName[1],
            type: "line",
            // stack: '总量',
            itemStyle: {
              normal: {
                lineStyle: {
                  // color: "#E85966",
                  width: 1
                }
              }
            },
            data: this.industryCounts[1]
          },
          {
            name: this.industryName[2],
            type: "line",
            // stack: '总量',
            itemStyle: {
              normal: {
                lineStyle: {
                  // color: "#2ADCE1",
                  width: 1
                }
              }
            },
            data: this.industryCounts[2]
          },
          {
            name: this.industryName[3],
            type: "line",
            // stack: '总量',
            itemStyle: {
              normal: {
                lineStyle: {
                  // color: "#966AE9",
                  width: 1
                }
              }
            },
            data: this.industryCounts[3]
          },
          {
            name: this.industryName[4],
            type: "line",
            // stack: '总量',
            itemStyle: {
              normal: {
                lineStyle: {
                  // color: "#966AE9",
                  width: 1
                }
              }
            },
            data: this.industryCounts[4]
          },
          {
            name: this.industryName[5],
            type: "line",
            // stack: '总量',
            itemStyle: {
              normal: {
                lineStyle: {
                  // color: "#966AE9",
                  width: 1
                }
              }
            },
            data: this.industryCounts[5]
          },
          {
            name: this.industryName[6],
            type: "line",
            // stack: '总量',
            itemStyle: {
              normal: {
                lineStyle: {
                  // color: "#966AE9",
                  width: 1
                }
              }
            },
            data: this.industryCounts[6]
          },
          {
            name: this.industryName[7],
            type: "line",
            // stack: '总量',
            itemStyle: {
              normal: {
                lineStyle: {
                  // color: "#966AE9",
                  width: 1
                }
              }
            },
            data: this.industryCounts[7]
          }
        ]
      });
    },
    map() {},
    // 萧山八大产业
    getIndustryXs() {
      let organ_id = this.organ_id;
      this.$http
        .get('https://wts.weitianshi.cn' + this.URL.adminUrl.industryXs + "?organ_id=" + organ_id + "&&range_id=" + this.range_id + "&&data_type=" + this.data_type)
        .then((res) => {
          let data = res.data.data;
          this.industryXs = data;
          this.industry = this.industryXs.map((item,index,arr) => {
            return item.id;
          })
          this.industrialTypeId = this.industryXs[0].id;
          this.rankIndustrialTypeId = this.industryXs[0].id;
          this.projectIndustrialTypeId = this.industryXs[0].id;
          this.industrialTypeName = this.industryXs[0].name;
          this.rankIndustrialTypeName = this.industryXs[0].name;
          this.projectIndustrialTypeName = this.industryXs[0].name;
          this.addend = res.data.range.addend
          this.multiplier = res.data.range.multiplier

          // 在这里请求是因为下面的请求需要用到八大产业的数据
          this.getIndustryHot();
          this.getIndustryPattern();
          this.getIndustryCityRank();
          this.getIndustryTrend();
          this.getIndustryDetail();
          this.getProjectList();
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 产业热度
    getIndustryHot() {
      let organ_id = this.organ_id;
      // 0 不变 1上升 2下降
      this.$http
        .get('https://wts.weitianshi.cn' + this.URL.adminUrl.industryHot + "?organ_id=" + organ_id + "&&range_id=" + this.range_id + "&&data_type=" + this.data_type)
        .then(res => {
          let data = res.data.data;
          this.industryHot = data;
        })
        .catch(err => {
          // console.log(err);
        });
    },
    // 产业格局
    getIndustryPattern() {
      let organ_id = this.organ_id;
      this.$http
        .get('https://wts.weitianshi.cn' + this.URL.adminUrl.industryPattern + "?organ_id=" + organ_id + "&&range_id=" + this.range_id + "&&data_type=" + this.data_type)
        .then(res => {
          let data = res.data.data;
          this.industryPattern = data;
          this.tzl = [];
          this.tzzq = [];
          let tzl = data.map(function(item, index, arr) {
            return item.project_count;
          });
          let tzzq = data.map(function(item, index, arr) {
            return item.average_time_space;
          });
          let tzlMin = Math.min.apply( Math, tzl);
          let tzzqMin = Math.min.apply( Math, tzzq);
          this.tzl = tzl.map(function(item, index, arr) {
            return item-tzlMin;
          });
          this.tzzq = tzzq.map(function(item, index, arr) {
            return item-tzzqMin;
          });
          let tzlMax = Math.max.apply(null, this.tzl) * 1.1;
          let tzzqMax = Math.max.apply(null, this.tzzq) * 1.1;
          this.tzlb = this.tzl.map(function(item, index, arr) {
            return item / tzlMax;
          });
          this.tzzqb = this.tzzq.map(function(item, index, arr) {
            return item / tzzqMax;
          });
        })
        .catch(err => {
          // console.log(err);
        });
    },
    // 产业分布
    getIndustryCityRank() {
      let organ_id = this.organ_id;
      let id = this.rankIndustrialTypeId;
      this.$http
        .get('https://wts.weitianshi.cn' + this.URL.adminUrl.industryCityRank + id + "?organ_id=" + organ_id + "&&range_id=" + this.range_id + "&&data_type=" + this.data_type)
        .then(res => {
          let data = res.data.data;
          if(data.length == 0){
            error('暂无数据')
          }else{
            let maxConut = data[0].count;
            data.map(function(item,index,arr){
              data[index].percentage = item.count/maxConut
            });
            this.industryCityRank = data;
          }
          this.showRankIndustrialTypeList = false;
        })
        .catch(err => {
          // console.log(err);
        });
    },
    // 产业趋势
    getIndustryTrend() {
      let industry = this.industry;
      let industryXs = this.industryXs;
      let organ_id = this.organ_id;
      let industryName = industry.map(function(item, index, arr) {
        return industryXs[index].name;
      });
      this.industryName = industryName;
      this.$http
        .post('https://wts.weitianshi.cn' + this.URL.adminUrl.industryTrend, {
          industry,
          data_type:this.data_type,
          organ_id,
          range_id:this.range_id
        })
        .then(res => {
          let that = this
          let data = res.data.data;
          this.industryTrend = data;
          let industryCounts = data.map(function(item, index, arr) {
            return item.data.map(function(a, b, c) {
              return (Math.round((a.count+that.addend)*that.multiplier*100)/100);
            });
          });
          this.industryCounts = industryCounts;
          this.zhexian();
        })
        .catch(err => {
          // console.log(err);
        });
    },
    // getXiaoShanProjectList
    // 招商项目
    getProjectList() {
      let industry = Number(this.projectIndustrialTypeId);
      let organ_id = this.organ_id;
      let type = Number(this.projectListType);
      this.$http
        .post('https://wts.weitianshi.cn' + this.URL.adminUrl.getXiaoShanProjectList, {
          industry,
          type,
          data_type:this.data_type,
          organ_id,
          range_id:this.range_id
        })
        .then(res => {
          let data = res.data.data.data;
          if(data.length > 3){
            data.length = 3;
          }
          this.projectList = data;
          this.showProjectindustrialTypeList = false;
          this.showProjectTypeList = false;
        })
        .catch(err => {
          // console.log(err);
        });
    },
    // 细分产业
    getIndustryDetail() {
      let industrialTypeId = this.industrialTypeId
      let organ_id = this.organ_id;
      this.$http
        .get('https://wts.weitianshi.cn' + this.URL.adminUrl.industryDetail + industrialTypeId + "?organ_id=" + organ_id + "&&range_id=" + this.range_id + "&&data_type=" + this.data_type)
        .then(res => {
          let data = res.data.data.data;
          let projectCount = data.map(function(item, index, arr) {
            return item.project_count;
          });
          let dataSort = data.sort((x, y) => {
            return y.project_count - x.project_count;
          });
          if(dataSort.length > 22){
            dataSort.length = 22;
          }
          let max = dataSort[0].project_count;
          dataSort.sort(function() {
            return 0.5 - Math.random();
          });
          dataSort.map(function(item, index, arr) {
            dataSort[index].size = item.project_count / max;
          });
          let empty = {
            industry_id: 0,
            industry_name: "",
            project_count: 0,
            size: 0
          };
          dataSort.unshift(empty, empty);
          if(dataSort.length > 4){
            dataSort.splice(4, 0, empty, empty);
          }
          if(dataSort.length > 14){
            dataSort.splice(14, 0, empty, empty);
          }
          if(dataSort.length > 24){
            dataSort.splice(24, 0, empty, empty);
          }
          this.industryDetail = dataSort;
          this.showindustrialTypeList = false;
        })
        .catch(err => {
          // console.log(err);
        });
    },
    // 选择产业(细分产业)
    getIndustrialType(id, name) {
      this.industrialTypeId = id;
      this.industrialTypeName = name;
      this.getIndustryDetail();
    },
    // 选择产业(产业分布)
    getRankIndustrialType(id, name) {
      this.rankIndustrialTypeId = id;
      this.rankIndustrialTypeName = name;
      this.getIndustryCityRank();
    },
    // 选择产业(招商项目)
    getProjectIndustrialType(id, name) {
      this.projectIndustrialTypeId = id;
      this.projectIndustrialTypeName = name;
      this.getProjectList();
    },
    getProjectIType(type, name){
      this.projectListType = type;
      this.projectTypeName = name;
      this.getProjectList();
    },
    changeshowindustrialTypeList() {
      let a = this.showindustrialTypeList;
      this.showindustrialTypeList = !a;
    },
    changeshowRankIndustrialTypeList() {
      let a = this.showRankIndustrialTypeList;
      this.showRankIndustrialTypeList = !a;
    },
    changeshowProjectIndustrialTypeList() {
      let a = this.showProjectindustrialTypeList;
      this.showProjectindustrialTypeList = !a;
    },
    changeshowProjectTypeList() {
      let a = this.showProjectTypeList;
      this.showProjectTypeList = !a;
    }
  },
  mounted() {
    let organ_id = this.organ_id;
    if(this.jumpType != 'tab'){
      if(document.documentElement.clientWidth < 1200){
        // this.$router.push('/xiaoShanInvestmentM')
        this.$router.push({path:'/xiaoShanInvestmentM',query:{data_type:this.data_type,organ_id:organ_id}});
      }
    }
    document.documentElement.style.fontSize = (document.documentElement.clientWidth / 1920) * 100 + "px";
    window.onresize = function temp() {
      document.documentElement.style.fontSize = (document.documentElement.clientWidth / 1920) * 100 + "px";
    }
    // this.map();
    this.getIndustryXs();
    this.mountedTime = setInterval(() => {
      this.getIndustryXs();
    }, 600000);
  },
  beforeDestroy() {
    if(this.mountedTime){
      clearInterval(this.mountedTime)
    }
  },  
  destroyed() {
    document.documentElement.style.fontSize ="16"+"px"
    window.onresize = function temp() {
      document.documentElement.style.fontSize ="16" + "px";
    }
  }
};
</script>

<style lang="less" scoped>
.xiaoShanInvestment {
  width: 100%;
  background-color: #040521;
  padding-top: 0.24rem;
  padding-bottom: 0.24rem;
  font-family: PingFangSC-Regular;
  position: absolute;
  top: 0;  
}
.top {
  margin: 0 auto;
  width: 6.6rem;
  height: 1.08rem;
  text-align: center;
  font-size: 0.44rem;
  font-weight: 500;
  line-height: 1.08rem;
  color: #3786df;
  border: 1px solid #265fd0;
  box-shadow: 0px 0px 1rem 0px #265fd0 inset;
  border-radius: 4px;
  margin-bottom: 0.24rem;
}
.top a{
    color: #3786df;
}
.conterL1,
.conterL2,
.conterL3,
.conterR1,
.conterR2,
.conterR3 {
  background: rgba(0, 0, 0, 0);
  width: 9.24rem;
  border: 1px solid #265fd0;
  box-shadow: 0px 0px 0.5rem 0px #265fd0 inset;
  // box-shadow: 0px 0px 0.5rem 0px rgba(5,10,30,0.63) inset;
  // box-shadow:0px 0px 0px rgba(5,10,30,0.63);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0.2rem;
  position: relative;
}
.contert {
  display: flex;
  justify-content: center;
}
.contertL {
  margin-right: 0.24rem;
}
.conterL1 {
  height: 1.08rem;
  display: flex;
  justify-content: space-around;
  text-align: center;
  box-shadow: 0px 0px 0.7rem 0px #265fd0 inset;
}
.conterL2 {
  height: 3.72rem;
  margin: 0.24rem 0;
  color: #c5d8ff;
  position: relative;
}
.conterL3 {
  height: 3.72rem;
}
.conterR1 {
  height: 3.52rem;
}
#zhexian {
  font-size: 0.24rem;
  font-weight: 600;
}
.conterR2 {
  height: 2.6rem;
  margin: 0.24rem 0;
  color: #c5d8ff;
}
.conterR3 {
  height: 2.4rem;
  color: #c5d8ff;
  padding: 0rem;
}
.heatNum {
  font-size: 0.28rem;
  line-height: 0.32rem;
  color: #ff8f2b;
  margin-bottom: 0.16rem;
  font-family: DINNextLTPro-Regular;
  position: relative;
}
.heatNumLi {
  display: inline-block;
}
.heatName {
  font-size: 0.14rem;
  line-height: 0.12rem;
  color: #92a7e0;
  font-weight: 600;
}
.arrow {
  width: 0.09rem;
  position: relative;
  left: -0.08rem;
  top: -0.08rem;
}
.heatLi {
  position: relative;
}
.title02 {
  font-size: 0.24rem;
  font-weight: 600;
  color: #c5d8ff;
}
.box02 {
  padding: 0.5rem 0.2rem 0.3rem 0.3rem;
  position: relative;
}
.dashedLi {
  height: 0.5rem;
  border-top: 1px dashed #265fd0;
  opacity: 0.5;
}
.conterL2x {
  position: absolute;
  width: 8.34rem;
  height: 1px;
  background: #265fd0;
  opacity: 0.72;
  bottom: 0.6rem;
  left: 0.5rem;
}
.conterL2y {
  position: absolute;
  width: 1px;
  height: 2.2rem;
  background: #265fd0;
  opacity: 0.72;
  bottom: 0.6rem;
  left: 0.5rem;
}
.conterL2xx,
.conterL2yy {
  position: absolute;
  color: #92a7e0;
  text-align: center;
  font-size: 0.14rem;
  line-height: 0.18rem;
  font-weight: 500;
}
.conterL2xx {
  right: 0.5rem;
  bottom: 0.3rem;
}
.conterL2yy {
  text-align: center;
  width: 0.28rem;
  top: 1rem;
  left: 0.2rem;
}
.data02Li {
  position: absolute;
  opacity: 0.7;
  overflow: hidden;
}
.data02Li:hover {
  opacity: 1;
  overflow: visible;
  z-index: 1000;
}
.selectType {
  position: absolute;
  top: 0.27rem;
  left: 1.28rem;
  color: #966ae9;
  font-size: 0.14rem;
  width: 1.5rem;
  overflow: hidden;
  z-index: 1000;
}
.conterR3 .selectType{
  left: 1.8rem;
}
.conterR3 .selectType:nth-of-type(3){
  left: 3rem;
}
.industrialType:hover {
  opacity: 0.5;
}
.industrialTypeList {
  width: 1.5rem;
  border-radius: 3px;
  background-color: #fff;
  position: relative;
  z-index: 1000;
}
.industrialTypeLi {
  height: 0.3rem;
  overflow: hidden;
  line-height: 0.3rem;
}
.industrialTypeLi:hover {
  background-color: #d3dce6;
}
.tips {
  margin-top: 0.68rem;
  width: 1rem;
}
.describe {
  font-size: 0.16rem;
  color: #92a7e0;
  margin-left: 0.08rem;
}
.circles {
  display: flex;
  margin-top: 0.11rem;
}
.circle {
  background-color: #92a7e0;
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 50%;
}
.circle:nth-of-type(1) {
  margin: 0.1rem 0.05rem;
  width: 0.1rem;
  height: 0.1rem;
}
.circle:nth-of-type(2) {
  margin: 0.05rem 0.05rem;
  width: 0.2rem;
  height: 0.2rem;
}
.circle:nth-of-type(3) {
  margin: 0 0.05rem;
  width: 0.3rem;
  height: 0.3rem;
}
.graphBubbles {
  width: 8rem;
  height: 2rem;
  position: absolute;
  top: 0.5rem;
  right: 0.2rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.graphBubble {
  position: relative;
  width: 0.8rem;
  height: 0.6rem;
  // background-color: yellow;
}
.bubble {
  border-radius: 50%;
  margin: 0 auto;
  background-color: #fff;
}
.bubbleName {
  text-align: center;
  font-size: 0.12rem;
  color: #92a7e0;
  overflow: visible;
  word-spacing: normal;
  margin-top: 0.05rem;
}
.bigGraphBubble {
  position: absolute;
  width: 0.7rem;
  height: 0.7rem;
  border: 0.15rem solid #6d4eb1;
  border-radius: 50%;
  background-color: #966ae9;
  font-size: 0.18rem;
  font-weight: 400;
  color: #fff;
  line-height: 0.4rem;
  text-align: center;
  top: 0.5rem;
  right: 3.5rem;
  box-shadow: 0,0,0,0.05rem,#6d4eb1;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;  
}
.conterL3Img{
  width: 4.4rem;
  height: 3rem;
  position: relative;
  left: 1.2rem;
}
.mapImg{
  width: 4.4rem;
  height: 3rem;
}
.ranking{
  position: absolute;
  width: 3rem;
  height: 3rem;
  right: 0.2rem;
  top: 0.5rem;
  color: #92A7E0;
}
.rankingTitle{
  font-size: 0.16rem;
  font-weight: 600;
  margin-bottom: 0.23rem;
}
.cityLi{
  display: flex;
  margin-bottom: 0.3rem;
}
.cityName{
  width: 1rem;
  font-size: 0.14rem;
  line-height: 0.14rem;
  margin-right: 0.24rem;
}
.cityCount{
  background: linear-gradient(to right, #0155FF , #02A9FF);
  height: 0.14rem;
}
.conterR3 .title02{
  padding: 0.2rem 0 0 0.2rem;
}
.projectList{
  width: 9.24rem;
}
.projectLi{
  display: flex;
  margin: 0.16rem 0;
  font-size: 0.14rem;
}
.projectLi div{
  margin: 0 0.15rem;
  white-space: nowrap;
  overflow: hidden;
}
.projectLi .logo{
  width: 0.2rem;
  height: 0.2rem;
}
.projectLi .logo img{
  width: 0.2rem;
  height: 0.2rem;
}
.projectLi .companyName{
  width: 1rem;
  color: #92A7E0;
}
.projectLi .intro{
  width: 1rem;
  color: #92A7E0;
}
.projectLi .industry{
  width: 1rem;
  color: #92A7E0;
}
.projectLi .stage{
  width: 0.9rem;
  color: #92A7E0;
}
.projectLi .special{
  width: 0.69rem;
  color: #92A7E0;
}
.projectLi .score{
  width: 0.4rem;
  color: #6BD57C;
}
.projectLi .investor{
  width: 0.6rem;
  color: #EE6161;
}
.projectLi .mobile{
  width: 1.2rem;
  color: #92A7E0;
}
.projectLi .corporation{
  width: 0.6rem;
  color: #92A7E0;
}
.projectListHead{
  width:rem;
  color: #966AE9;
  padding: 0.09rem 0;
  font-size: 0.14rem;
  display: flex;
  border-bottom: 1px solid #265FD0;
}
// .projectListHead div{
//   display: inline-block;
// }
.projectListHead div:nth-of-type(1){
  margin-left: 0.6rem;
  width: 1rem;
}
.projectListHead div:nth-of-type(2){
  margin-left: 0.15rem;
  width: 1rem;
}
.projectListHead div:nth-of-type(3){
  margin-left: 0.15rem;
  width: 0.9rem;
}
.projectListHead div:nth-of-type(4){
  margin-left: 0.15rem;
  width: 0.9rem;
}
.projectListHead div:nth-of-type(5){
  margin-left: 0.15rem;
  width: 0.69rem;
}
.projectListHead div:nth-of-type(6){
  margin-left: 0.15rem;
  width: 0.5rem;
}
.projectListHead div:nth-of-type(7){
  margin-left: 0.15rem;
  width: 0.7rem;
}
.projectListHead div:nth-of-type(8){
  margin-left: 0.15rem;
  width: 1.1rem;
}
.projectListHead div:nth-of-type(9){
  margin-left: 0.15rem;
  width: 0.6rem;
}
</style>

